import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Gpso2 from "/src/components/img/gpso/2"

export default () => (
  <Layout>
    <SEO title="Příprava přístroje na geodetické měření, chyby při měření úhlů" />
    <h1>Příprava přístroje na geodetické měření, chyby při měření úhlů</h1>

    <p>Před měřen&iacute;m vodorovn&yacute;ch či svisl&yacute;ch &uacute;hlů je potřeba dan&yacute; př&iacute;stroj (dnes teodolit, tot&aacute;ln&iacute; stanice) připravit, abychom omezily určit&eacute; typy chyb při jejich měřen&iacute;. Celkem rozli&scaron;ujeme <strong>chyby př&iacute;strojov&eacute;, měřičsk&eacute; </strong>a <strong>z&nbsp;prostřed&iacute;.</strong></p>
    <hr /><h2>Př&iacute;prava teodolitu a tot&aacute;ln&iacute; stanice</h2>
    <p>Př&iacute;prava teodolitu a tot&aacute;ln&iacute; stanice na měřen&iacute; prob&iacute;h&aacute; v&nbsp;6 z&aacute;kladn&iacute;ch kroc&iacute;ch:</p>
    <ul>
    <li><strong>př&iacute;prava stativu </strong>(postaven&iacute; př&iacute;stroje zhruba nad bod)</li>
    <li><strong>hrub&aacute; centrace </strong>(posouv&aacute;n&iacute; nad bod posunov&aacute;n&iacute;m noh stativu za použit&iacute; optick&eacute;ho dostřeďovače)</li>
    <li><strong>hrub&aacute; horizontace </strong>(aby byla svisl&aacute; osa př&iacute;stroje kolm&aacute; k&nbsp;vodorovn&eacute; rovině; sledov&aacute;n&iacute; krabicov&eacute; libely, hon&iacute;me bublinu vysunov&aacute;n&iacute;m noh stativu tak, aby byla co nejv&iacute;ce ve středu)</li>
    <li><strong>jemn&aacute; centrace</strong> (uvolněn&iacute; up&iacute;nac&iacute;ho &scaron;roubu, posouv&aacute;n&iacute; po hlavě stativu, za&scaron;roubov&aacute;n&iacute;)</li>
    <li><strong>jemn&aacute; horizontace </strong>(pomoc&iacute; stavěc&iacute;ch &scaron;roubů na trojnožce př&iacute;stroje, hon&iacute;me bublinu v trubicov&eacute; libele v&nbsp;poloze jedna a pot&eacute; poloze dva; viz obr. 1)</li>
    <li><strong>kontrola </strong>(jemn&eacute; centrace a posunut&iacute;m o 90 &deg; a zkontrolov&aacute;n&iacute;m bubliny i jemn&eacute; horizontace &ndash; pokud nen&iacute; v&nbsp;poř&aacute;dku, opakovat)</li>
    </ul>
<Gpso2/>
    <hr /><h2>Chyby při měřen&iacute; vodorovn&yacute;ch směrů</h2>
    <p>Při měřen&iacute; vodorovn&yacute;ch směrů vznikaj&iacute; kupř&iacute;kladu n&aacute;sleduj&iacute;c&iacute; <strong>př&iacute;strojov&eacute; chyby</strong>:</p>
    <ul>
    <li>z&nbsp;nespr&aacute;vn&eacute;ho urovn&aacute;n&iacute; alhid&aacute;dy &ndash; libely (nesplněn&aacute; 1. osov&aacute; podm&iacute;nka)</li>
    <li>chyba kolimačn&iacute; (nesplněn&aacute; 2. osov&aacute; podm&iacute;nka, lze odstranit kombinac&iacute; čten&iacute; v&nbsp;1. a 2. poloze)</li>
    <li>chyba ze sklonu točn&eacute; osy dalekohledu (nesplněn&aacute; 3. osov&aacute; podm&iacute;nka, lze odstranit kombinac&iacute; čten&iacute; v&nbsp;1. a 2. poloze)</li>
    <li>chyba z excentricity alhid&aacute;dy (osa alhid&aacute;dy neproch&aacute;z&iacute; přesně středem kruhu, lze odstranit kombinac&iacute; čten&iacute; v&nbsp;1. a 2. poloze)</li>
    <li>chyba ze sklonu z&aacute;měrn&eacute; roviny</li>
    <li>chyba z&nbsp;dělen&iacute; kruhu (sn&iacute;žen&iacute; vlivu měřen&iacute;m metodou ve skupině)</li>
    <li>chyba z&nbsp;nediametr&aacute;ln&iacute; polohy indexů (konstantn&iacute; chyba &ndash; každ&yacute; vypočten&yacute; &uacute;hel, kter&yacute; je vypočten rozd&iacute;lem dvou směrů, je od t&eacute;to chyby opro&scaron;těn)</li>
    </ul>
    <p><strong>Chyby měřičsk&eacute;</strong> jsou pak n&aacute;sleduj&iacute;c&iacute;:</p>
    <ul>
    <li>chyba z&nbsp;horizontace př&iacute;stroje</li>
    <li>chyba z&nbsp;centrace př&iacute;stroje</li>
    <li>chyba z&nbsp;excentricity sign&aacute;lu</li>
    <li>chyba v&nbsp;c&iacute;len&iacute;</li>
    <li>chyba v&nbsp;čten&iacute;</li>
    </ul>
    <p><strong>Chyby z&nbsp;prostřed&iacute;</strong> pak rozli&scaron;ujeme tyto:</p>
    <ul>
    <li>chyba z&nbsp;refrakce</li>
    <li>chyba z&nbsp;vibrace vzduchu</li>
    </ul>
    <hr /><h2>Chyby měřen&yacute;ch svisl&yacute;ch &uacute;hlů</h2>
    <p>Při zji&scaron;ťov&aacute;n&iacute; svisl&yacute;ch &uacute;hlů doch&aacute;z&iacute; např&iacute;klad k&nbsp;n&aacute;sleduj&iacute;c&iacute;m <strong>př&iacute;strojov&yacute;ch chyb&aacute;m</strong>:</p>
    <ul>
    <li>indexov&aacute; chyba (odeč&iacute;tac&iacute; zař&iacute;zen&iacute; &ndash; &bdquo;indexy&ldquo;)</li>
    <li>př&iacute;strojov&eacute; chyby jmenovan&eacute; u měřen&iacute; vodorovn&yacute;ch směrů (tj. <strong>osov&eacute; chyby</strong>)</li>
    </ul>
    <p><strong>Chyby měřick&eacute;</strong> pak zaznamen&aacute;v&aacute;me např&iacute;klad tyto:</p>
    <ul>
    <li>z nepřesn&eacute;ho urovn&aacute;n&iacute; indexov&eacute; libely (kompenz&aacute;toru)</li>
    <li>chyba v zac&iacute;len&iacute; dalekohledu</li>
    <li>chyba v odečten&iacute; &uacute;hlov&eacute; stupnice</li>
    <li>chyba v určen&iacute; v&yacute;&scaron;ky teodolitu a c&iacute;le</li>
    <li>chyba způsoben&aacute; nepřesnou centrac&iacute; teodolitu</li>
    </ul>
    <p><strong>Chyby z vněj&scaron;&iacute;ho prostřed&iacute; </strong>existuj&iacute; pak n&aacute;sleduj&iacute;c&iacute;:</p>
    <ul>
    <li>vliv vertik&aacute;ln&iacute; refrakce</li>
    <li>vliv vibrace vzduchu</li>
    </ul>

    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Příprava přístroje na geodetické měření, chyby při měření úhlů</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/pristroje-a-pomucky-pro-mereni-smeru-a-uhlu/"><button className="inv">&larr; Nástroje pro geodetická měření úhlů a směrů</button></Link>
    <Link to="/zaklady-geodezie-gps/vypocet-smerniku-rajonu/"><button className="inv">Výpočet směrníku a rajónu &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
